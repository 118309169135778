import React from "react";

import NewsArticle from "../../../components/news-article/news-article.component";

import { LATEST_RATE_SHEET } from "../../../data/latest-rate-sheet.data";

const Article = () => (
  <NewsArticle>
    <h1 className="page-title">Changes to our mortgage products</h1>
    <p>With effect from Thursday 16 June 2022 we will be making the following changes to our range:</p>
    <p>
      <strong>Summary of changes</strong>
    </p>

    <p>
      <strong>UK Residential First Time Buyer Range</strong>
    </p>
    <ul>
      <li>2 Year Fixed Standard at 60%, 70%, 75%, 80%, 85%, 90% and 95% LTV have increased</li>
      <li>2 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85%, 90% and 95% LTV have increased</li>
      <li>3 Year Fixed Standard at 60%, 70%, 75%, 80%, 85%, 90% and 95% have increased</li>
      <li>3 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85%, 90% and 95% LTV have increased</li>
      <li>5 Year Fixed Standard at 60%, 70%, 75%, 80%, 85%, 90% and 95% LTV have increased</li>
      <li>5 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85%, 90% and 95% LTV have increased</li>
      <li>5 Year Fixed Premier Exclusive at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
    </ul>

    <p>
      <strong>UK Residential Home Mover Range</strong>
    </p>
    <ul>
      <li>2 Year Fixed Standard at 60%, 70%, 75%, 80%, 85%, 90% and 95% LTV have increased</li>
      <li>2 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85%, 90% and 95% LTV have increased</li>
      <li>3 Year Fixed Standard at 60%, 70%, 75%, 80%, 85%, 90% and 95% have increased</li>
      <li>3 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85%, 90% and 95% LTV have increased</li>
      <li>5 Year Fixed Standard at 60%, 70%, 75%, 80%, 85%, 90% and 95% LTV have increased</li>
      <li>5 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85%, 90% and 95% LTV have increased</li>
      <li>5 Year Fixed Premier Exclusive at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
    </ul>

    <p>
      <strong>UK Residential Remortgage Range</strong>
    </p>
    <ul>
      <li>2 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
    </ul>
    <ul>
      <li>2 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>3 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>3 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>5 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>5 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>5 Year Fixed Premier Exclusive at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
    </ul>

    <p>
      <strong>UK Residential Existing Customer Switching Range</strong>
    </p>
    <ul>
      <li>2 Year Fixed Standard at 60%, 70%, 75%, 80%, 85%, 90% and 95% LTV have increased</li>
      <li>2 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85%, 90% and 95% LTV have increased</li>
      <li>3 Year Fixed Standard at 60%, 70%, 75%, 80%, 85%, 90% and 95% have increased</li>
      <li>3 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85%, 90% and 95% LTV have increased</li>
      <li>5 Year Fixed Standard at 60%, 70%, 75%, 80%, 85%, 90% and 95% LTV have increased</li>
      <li>5 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85%, 90% and 95% LTV have increased</li>
      <li>5 Year Fixed Premier Exclusive at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
    </ul>

    <p>
      <strong>UK Residential Existing Customer Borrowing More Range</strong>
    </p>
    <ul>
      <li>2 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>2 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>3 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>3 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>5 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>5 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>5 Year Fixed Premier Exclusive at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
    </ul>

    <p>
      <strong>UK Buy to Let Existing Customer Switching Range</strong>
    </p>
    <ul>
      <li>2 Year Fixed Standard at 60%, 65% and 75% LTV have increased</li>
      <li>2 Year Fixed Fee Saver 60%, 65% and 75% LTV have increased</li>
      <li>5 Year Fixed Standard at 60%, 65% and 75% LTV have increased</li>
      <li>5 Year Fixed Fee Saver 60%, 65% and 75% LTV have increased</li>
      <li>2 Year Tracker Standard at 75% LTV has reduced</li>
    </ul>

    <p>
      <strong>UK Buy to Let Existing Customer Borrowing More Range</strong>
    </p>
    <ul>
      <li>2 Year Fixed Standard at 60%, 65% and 75% LTV have increased</li>
      <li>2 Year Fixed Fee Saver 60%, 65% and 75% LTV have increased</li>
      <li>5 Year Fixed Standard at 60%, 65% and 75% LTV have increased</li>
      <li>5 Year Fixed Fee Saver 60%, 65% and 75% LTV have increased</li>
      <li>2 Year Tracker Standard at 75% LTV has reduced</li>
    </ul>

    <p>
      <strong>UK Buy to Let Remortgage Range</strong>
    </p>
    <ul>
      <li>2 Year Fixed Standard at 60%, 65% and 75% LTV have increased</li>
      <li>2 Year Fixed Fee Saver 60%, 65% and 75% LTV have increased</li>
      <li>5 Year Fixed Standard at 60%, 65% and 75% LTV have increased</li>
      <li>5 Year Fixed Fee Saver 60%, 65% and 75% LTV have increased</li>
      <li>2 Year Tracker Standard at 75% LTV has reduced</li>
    </ul>

    <p>
      <strong>UK Buy to Let Purchase Range</strong>
    </p>
    <ul>
      <li>2 Year Fixed Standard at 60%, 65% and 75% LTV have increased</li>
      <li>2 Year Fixed Fee Saver 60%, 65% and 75% LTV have increased</li>
      <li>5 Year Fixed Standard at 60%, 65% and 75% LTV have increased</li>
      <li>5 Year Fixed Fee Saver 60%, 65% and 75% LTV have increased</li>
      <li>2 Year Tracker Standard at 75% LTV has reduced</li>
    </ul>

    <p>
      <strong>International Residential Range</strong>
    </p>
    <ul>
      <li>2 Year Fixed Standard at 60%, 70% and 75% LTV have increased</li>
      <li>2 Year Fixed Fee Saver at 60%, 70% and 75% LTV have increased</li>
      <li>3 Year Fixed Standard at 60%, 70% and 75% LTV have increased</li>
      <li>3 Year Fixed Fee Saver at 60%, 70% and 75% LTV have increased</li>
      <li>5 Year Fixed Standard at 60, 70% and 75% LTV have increased</li>
      <li>5 Year Fixed Fee Saver at 60%, 70% and 75% LTV have increased</li>
      <li>5 Year Fixed Premier Exclusive at 60%, 70% and 75% LTV have increased</li>
    </ul>

    <p>There are no changes to any other interest rates.</p>
    <p>An updated mortgage rate sheet will be published on Thursday 16 June 2022 to reflect these changes.</p>
    <p>
      <strong>PLEASE NOTE:</strong> A reminder that, whilst our recently launched cashback incentives are currently only
      available for First Time Buyers at 85%, 90% and 95% loan to value and Home Movers at 90% and 95% LTV, you can
      discuss cashback with customers across all LTV&rsquo;s being mindful of HSBC UK&rsquo;s standard lending criteria.
    </p>
    <p>
      <a title="This PDF opens in a new window" target="_blank" href={LATEST_RATE_SHEET.href}>
        Link to our latest rate sheet
      </a>
    </p>
  </NewsArticle>
);

export default Article;
